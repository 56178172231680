.react-datepicker {
  border: 1px solid #ccc; /* Custom border */
  
}

.react-datepicker__header {
  background-color: #f0f0f0; /* Custom header background */
}

.react-datepicker__input-container input {
  width: 195px; /* Example to make the input full width */
  padding: 10px; /* Example padding */
  border: 1px solid #d6d6d6; /* Example border */
  border-radius: 8px;
  box-sizing: border-box;
  font-weight: 600;
}

.react-datepicker__input-container {
  width: 100%;
}